import React from 'react'

import Countactus from '../Components/Countactus'




export default function Park1() {
  return (
    <div style={{overflowX:'hidden'}}>

        <Countactus/>
        

    </div>
  )
}
