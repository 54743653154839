import React from 'react'
import LatestNews from '../Components/LatestNews'
import AddNews from '../Components/AddNews'



export default function News() {
  return (
    <div style={{overflowX:'hidden'}}>
        
        <AddNews/>

    </div>
  )
}
